
export const getFrenchValue = value => value.fra || value.fr || value;

export const getLangValue = (value, lang = 'en') => value[lang] || getFrenchValue(value);

export const buildRawValue = (mainClassificationId = null, lang = 'en') => (value, classificationId, marketplaces = []) => {
  if (!classificationId
    || !mainClassificationId
    || classificationId.length === 0
    || classificationId.includes('*')
    || (mainClassificationId && mainClassificationId.some(c => classificationId.includes(c)))
  ) {
    return ({
      id: getFrenchValue(value),
      name: getLangValue(value, lang),
      classificationId,
      marketplaces,
    });
  }
  return null;
};

export const buildRawValues = (values, simple = false) => {
  // due to using methods vue 2,  when setup the function
  // some arguments are not passed but a function
  // need to check if values variable is an array
  if (!Array.isArray(values)) return [];
  return values.map((value) => {
    const buildValue = buildRawValue();
    if (simple) return getFrenchValue(value.value);
    return buildValue(value.value, value.classificationId, value.marketplaces);
  });
};
