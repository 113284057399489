<template>
  <v-autocomplete
    :chips="chips"
    :filled="filled"
    :items="selectValues"
    :label="label || keyAttr"
    :messages="getDefaultValues()"
    :loading="isCompletudeRequired(bindAttribute, currentIssues)"
    :multiple="multipleSelection"
    :hint="attribute.getHelp()"
    item-text="name"
    item-value="id"
    menu-props="auto"
    persistent-hint
    :value-comparator="compareValue"
    v-model="valueAttribute"
  >
    <template
      v-if="hasRight('cobapp.v1:pim:*:read')"
      v-slot:selection="{ item }">
      <v-chip>
        <value-marketplace v-model="item" />
      </v-chip>
    </template>
    <template
      v-if="hasRight('cobapp.v1:pim:*:read')"
      v-slot:item="{ item, on, attrs }">
      <v-simple-checkbox
        v-if="multipleSelection"
        v-on="on"
        :disabled="attrs.disabled"
        :value="attrs.inputValue"
        :ripple="attrs.ripple"
      />
      <value-marketplace v-model="item" />
    </template>
  </v-autocomplete>
</template>
<script>
import AttributeBaseMixins from '@/mixins/attribute-base';
import CompletudeBaseMixins from '@/mixins/completude-base';
import LangBaseMixins from '@/mixins/lang-base';
import ProfileMixins from '@/mixins/profile-base';
import ValueMarketplace from '@/components/helper/ValueMarketplace';

export default {
  mixins: [ProfileMixins, LangBaseMixins, AttributeBaseMixins, CompletudeBaseMixins],
  name: 'attributeSelection',
  props: {
    itemsValue: {
      type: Array,
      required: false,
    },
  },
  components: {
    ValueMarketplace,
  },
  data: () => ({
    type: 'selection',
  }),
  methods: {},
};
</script>
