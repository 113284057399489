<template>
  <v-container>
    <v-row dense >
      <v-tabs
        v-if="tabMode"
        background-color="indigo"
        dark
        :vertical="vertical"
      >
        <v-tab
          v-for="(listGroup, groupName) in groups"
          :key="groupName"
        >
          {{ groupName }}
        </v-tab>
        <v-tab-item
          v-for="(listGroup, groupName) in groups"
          :key="groupName"
        >
          <v-col cols="12">
            <template v-for="key in itemsFiltered" >
              <core-attribute v-if="listGroup.includes(key)"
                :key="key"
                :keyAttr="key"
                :property="property"
                :item="item"></core-attribute>
            </template>
          </v-col>
        </v-tab-item>
      </v-tabs>
      <v-col v-else v-for="key in itemsFiltered" :key="key" cols="12">
        <template v-if="inputMode">
          <core-attribute :keyAttr="key" :property="property" :item="item" />
        </template>
        <template v-else>
          <v-text-field v-model="item[property][key]" readonly disabled :label="key" />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogBaseMixins from '@/mixins/dialog-base';

const DEFAULT_GROUP = 'Informations';

export default {
  props: {
    inputMode: {
      type: Boolean,
      default: true,
    },
    tabMode: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    tab: null,
  }),
  mixins: [DialogBaseMixins],
  computed: {
    ...mapGetters('form/rules', { textRules: 'text' }),
    attributes() {
      if (!this.tabMode) return {};
      return this.$store.getters['api/attribute/productAttribute'];
    },
    groups() {
      if (!this.tabMode) return {};
      const defaultGroup = {};
      defaultGroup[DEFAULT_GROUP] = [];
      return this.itemsFiltered.reduce((r, attr) => {
        const currAttr = this.attributes.find(a => a.bind === `infos.${attr}`);
        if (currAttr) {
          if (currAttr.group && typeof r[currAttr.group] === 'undefined') {
            r[currAttr.group] = [];
          }
          r[currAttr.group || DEFAULT_GROUP].push(attr);
        }
        return r;
      }, defaultGroup);
    },
    itemsValue: () => undefined,
    itemsKey: () => undefined,
    itemsFiltered() {
      return this.filters(this.item[this.property]);
    },
    itemsOldFilter() {
      return this.filtersOld(this.item[this.property]);
    },
  },
  methods: {
    filters(dataValues) {
      this.$log.debug('components.dialog.json.filters.started');
      if (!dataValues) {
        return [];
      }
      // @todo need to remove all theses attribute from dataValues fields
      const keysToRemove = [
        'att_validation_r',
        'validation_traduction_ref',
        'att_theagent_r',
        'att_legacy_r', // can be keep
        'att_provenance_r',
        'att_marques_r',
        'att_theagent_rc',
        'att_validation_rc',
        'history',
        'errors', // from product dataValues
      ];
      const fields = Object
        .keys(dataValues)
        .filter(w => keysToRemove.indexOf(w) === -1 && (w.indexOf('old_') === -1));
      return fields.sort((a, b) => {
        if (this.columns(a) >= this.columns(b)) {
          return -1;
        }
        return 1;
      });
    },
    filtersOld(dataValues) {
      if (!dataValues) {
        return [];
      }
      return Object
        .keys(dataValues)
        .filter(w => (w.indexOf('old_') !== -1));
    },
    columns(key) {
      const keysFor2columns = [
        'att_description_rc',
        'att_composition_rc',
        'att_complement_rc',
        'att_entretien_rc',
        'att_composition_doublure_rc',
        'att_composition_semelle_exterieure_rc',
        'att_composition_semelle_interieure_rc',
        'att_composition_exterieur_chaussure_rc',
      ];
      const keysFor3columns = ['att_titre_produit_r', 'reference_name', 'reference_col_name'];
      if (keysFor2columns.indexOf(key) !== -1) {
        return 12;
      }
      if (keysFor3columns.indexOf(key) !== -1) {
        return 6;
      }
      return 4;
    },
  },
};
</script>

<style></style>
