<template>
  <main>
    <!-- Velocity animation example -->
    <notifications group="ta-alfred"
                   position="bottom right"
                   classes="n-light"
                   :speed="500" />
    <!-- Custom template example -->
    <notifications group="ta-info"
                   :duration="5000"
                   :width="500"
                   animation-name="v-fade-left"
                   position="top left" />
    <transition mode="out-in">
      <router-view />
    </transition>
  </main>
</template>

<style lang="scss">
@import "@/styles/index.scss";
/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>
<script>
/* eslint no-param-reassign: 0 */
/* eslint max-len: 0 */
import { mapActions } from 'vuex';
// eslint-disable-next-line import/no-extraneous-dependencies
import camelCase from 'lodash/camelCase';
import { authenticationGuard } from '@/services/watchguard';
// checks to see if auth jwt token is valid or has expired, if it gets back 401 error log out
export default {
  created() {
    this.$http.interceptors.request.use(
      (config) => {
        const { token } = this.$store.getters;
        if (token && token !== 'undefined') {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest.Retry) {
          this.$store.dispatch('logout');
        }
      },
    );
    this.$http.interceptors.response.use(
      (response) => {
        // @todo need to create a new once algorithm call here
        //  because each time interceptors are called they store
        //  the data settings theagent and with properties reactive
        //  all computed with theagent computed react
        const optionsTa = Object.keys(response.headers).reduce((result, contentType) => {
          if (contentType.indexOf('ta-') === 0) {
            const key = camelCase(contentType.replace('ta-', ''));
            result[key] = response.headers[contentType];
          }
          return result;
        }, {});
        this.saveTheAgent(optionsTa);
        return response;
      },
      (error) => {
        const originalConfig = error.config;
        const statusCode = typeof error.response !== 'undefined' ? error.response.status : null;
        if (statusCode >= 500 || statusCode === 403) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            this.$log.error(error.response.data.message);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            this.$log.error(error.request);
          }
        } else if (statusCode === 401) {
          this.$log.debug('app.interceptors.error.refresh.token');
          // if token is present, we need to remove it
          if (this.$store.getters.isAuthenticated) {
            this.$store.commit('auth_reset_token');
            delete this.$http.defaults.headers.common.Authorization;
          }
          if (originalConfig.Retry) {
            return Promise.reject(new TypeError('app.interceptors.error.retry.stop'));
          }
          originalConfig.Retry = true;
          // call authenticationGuard
          return authenticationGuard({}, {}, () => this.$http(originalConfig)).catch((err) => {
            this.$auth.logout();
            this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
            throw err;
          });
        }
        return Promise.reject(error);
      },
    );
    // need to set $i18n locale for all translation from babel json
    this.status().then(({ user, scopes = [] }) => {
      this.$i18n.locale = user.preferences.lang || 'en';
      this.$store.commit('app/set_lang', user.preferences.lang || 'en');
      try {
        const token = { ...this.$store.getters.user };
        token.scope = scopes;
        this.$store.commit('auth_success', { token });
      } catch (e) {} // eslint-disable-line
    });
  },
  methods: {
    ...mapActions('settings', ['saveTheAgent']),
    ...mapActions('app', ['status']),
  },
};
</script>
