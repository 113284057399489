import * as Cookies from 'js-cookie';

export default {
  getItem: key => Cookies.get(key),
  // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
  setItem: (key, value) => {
    const expires = Number(process.env.VUE_APP_SESSION_EXPIRES) || 3;
    const isDocker = process.env.VUE_APP_BASEURL.indexOf('.localhost') !== -1;
    const rootDomain = isDocker ? 'theagent.localhost' : 'theagent.com';
    // remove the old vuex cookie from complet domain
    Cookies.remove(key, { secure: true });
    try {
      // deprecated but needed to remove old vuex cookie
      // This code is for Vue3 store pinia
      const data = JSON.parse(value);
      const defaultData = JSON.parse(Cookies.get('auth'));
      Cookies.set(
        'auth',
        JSON.stringify({
          token: { ...(defaultData.token || {}), ...(data.token || {}) },
          user: null,
        }),
        { expires, secure: !isDocker, domain: rootDomain },
      );
    } catch (e) {} // eslint-disable-line no-empty
    return Cookies.set(key, value, { expires, secure: !isDocker, domain: rootDomain });
  },
  removeItem: key => Cookies.remove(key),
};
