/* eslint max-len:0 */
import Vue from 'vue';
import { Attribute } from '@/models';
import LangSubMixins from '@/mixins/config/lang-sub-base';
import { getFrenchValue, getLangValue, buildRawValue } from '@/utils/rawvalue';

const namespace = 'mixins.form-base';

export default {
  mixins: [LangSubMixins],
  props: {
    force: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    attribute() {
      if (typeof this.customComponent !== 'undefined' && this.customComponent) {
        return this.customComponent;
      }
      const attr = this.$store.getters['api/attribute/attribute'](this.keyAttr || this.property);
      // attr is an array with name: default
      if (attr && !Array.isArray(attr)) {
        return Attribute(attr, {
          entityTypes: this.$store.getters['config/attribute.typeEntities'],
          currentLang: this.currentLang || 'fr',
        });
      }
      return Attribute(undefined);
    },
    valueAttribute: {
      get() {
        if (this.hasDataModel && this.keyAttr && typeof this.item.dataJson !== 'undefined') {
          return this.convertIfArrayNeeded(typeof this.item.dataJson[this.keyAttr] !== 'undefined'
            ? this.item.dataJson[this.keyAttr]
            : '');
        }
        if (this.keyAttr) {
          return this.convertIfArrayNeeded(typeof this.item[this.property][this.keyAttr] !== 'undefined'
            ? this.item[this.property][this.keyAttr]
            : '');
        }
        return this.convertIfArrayNeeded(this.item[this.property]);
      },
      set(value) {
        if (this.hasDataModel && this.keyAttr) {
          Vue.set(this.item.dataJson, this.keyAttr, value);
        } else if (this.keyAttr) {
          Vue.set(this.item[this.property], this.keyAttr, value);
        } else {
          Vue.set(this.item, this.property, value);
        }
      },
    },
    bindAttribute() {
      return this.keyAttr || this.property;
    },
    selectValues() {
      if (!this.attribute.values || this.attribute.values.length === 0) {
        return [];
      }
      const debug = this.$store.getters['settings/theagent'].sillyMode;
      // https://github.com/vuejs/eslint-plugin-vue/blob/master/docs/rules/no-side-effects-in-computed-properties.md
      // .slice makes a copy of the array, instead of mutating the orginal
      let itemsValue = [];

      try {
        itemsValue = this.attribute.values.slice(0);
      } catch (err) {
        this.$log.error(`${namespace}.selectvalues.value.itemsvalue.failed`);
        if (debug) this.$log.error(err);
        if (debug) this.$log.error(err);
      }
      if (!this.multipleSelection) {
        // add default value
        itemsValue.unshift({
          value: 'Empty value',
          classificationId: ['*'],
        });
      }
      if (this.item[this.property] && this.attribute.isAttribute()) {
        if (debug && this.isEmpty(this.item[this.property][this.keyAttr])) {
          this.$log.error(`${namespace}.no.attribute.value.for.${this.keyAttr}`);
        }
        const valueFromItem = [];
        if (Array.isArray(this.item[this.property][this.keyAttr])) {
          if (!this.attribute.isMultiple() && this.item[this.property][this.keyAttr].length > 1) {
            this.$log.error(`${namespace}.attribute.value.is.not.multiple.but.too.many.value`);
          }
          if (this.force) {
            valueFromItem.push(...this.item[this.property][this.keyAttr]);
          }
        } else if (this.keyAttr && typeof this.item[this.property][this.keyAttr] !== 'undefined') {
          if (this.force) {
            // all attribute values from database
            valueFromItem.push(this.item[this.property][this.keyAttr]);
          }
        } else if (typeof this.item[this.property] !== 'undefined') {
          if (this.force) {
            // all direct values from database
            valueFromItem.push(this.item[this.property]);
          }
        } else {
          this.$log.error(`${namespace}.attribute.field.${this.keyAttr || this.property}.have.not.value`);
        }
        if (this.force) {
          valueFromItem.forEach((value) => {
            // we store only french value in database, for the display the others languages are only for interface
            const index = itemsValue.findIndex(v => this.getFrenchValue(v).slugify() === this.getFrenchValue(value).slugify());
            if (index === -1) {
              itemsValue.push({
                name: value,
                id: this.getFrenchValue(value),
              });
            }
          });
        }
      }
      return itemsValue.reduce((result, { value, classificationId = [], marketplaces = [] }) => {
        const raw = this.buildRawValue(value, classificationId, marketplaces);
        if (raw) {
          result.push(raw);
        }
        return result;
      }, []);
    },
    radioValues() {
      if (typeof this.attribute.values === 'undefined') {
        return [];
      }
      return this.attribute.values.reduce((result, { value, classificationId = [], marketplaces = [] }) => {
        const raw = this.buildRawValue(value, classificationId, marketplaces);
        if (raw) {
          result.push({
            value: raw.id,
            label: raw.name,
            classificationId: raw.classificationId,
            marketplaces: raw.marketplaces || [],
          });
        }
        return result;
      }, []);
    },
    multipleSelection() {
      const debug = this.$store.getters['settings/theagent'].sillyMode;
      try {
        return this.attribute.isMultiple();
      } catch (err) {
        this.$log.error(`${namespace}.attribute.field.${this.keyAttr || this.property}.has.multiple.issue`);
        if (debug) this.$log.error(err);
      }
      return false;
    },

  },
  methods: {
    getFrenchValue,
    getLangValue(value) {
      return getLangValue(value, this.currentLang);
    },
    buildRawValue(value, classificationId, marketplaces = []) {
      const classification = this.$store.getters['classification/get'](this.$store.getters['product/product'].classificationId);
      let mainClassificationIds = null;
      if (classification && Array.isArray(classification.path) && classification.path.length > 0) {
        mainClassificationIds = classification.path;
      } else if (this.$store.getters['product/product'].classificationId) {
        mainClassificationIds = [this.$store.getters['product/product'].classificationId];
      }
      const buildValue = buildRawValue(mainClassificationIds, this.currentLang);
      return buildValue(value, classificationId, marketplaces);
    },
    /**
     * convert string to array if multiSelection is setted in database
     *
     * @param  {Mixed} value String|Array
     * @return {Mixed}       [description]
     */
    convertIfArrayNeeded(value) {
      if (this.multipleSelection && !Array.isArray(value)) {
        if (typeof value === 'string' && value.indexOf(',') !== -1) {
          return value.split(',').map(v => v.trim());
        }
        return value ? [value] : [];
      }
      return value;
    },
    isEmpty(values) {
      if (typeof values === 'string' && !values) return true;
      if (Array.isArray(values) && values.length === 1 && typeof values[0] === 'string' && !values[0]) return true;
      if (Array.isArray(values) && values.length === 0) return true;
      return false;
    },
    bindLangAttribute(lang) {
      return `${this.bindAttribute}.${lang}`;
    },


  },
};
